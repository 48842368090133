<template>
  <div class="hawk-eye">
    <van-loading
      size="24px"
      vertical
      v-if="isLoading"
      class="loading"
      color="#0094ff"
    >链接校验中</van-loading>
    <div v-else class="upload-module">
      <div v-if="uploadEntries" ref="testref">
        <div class="task-upload" v-for="(item, index) in uploadEntries" :key="index">
          <div class="task-upload-file">
            <div class="upload-top">
              <span class="title">{{item.tag}}</span>
            </div>
            <div>
              <image-collection
                ref="uploadImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :check-repeat="true"
                :imageType="item.value"
                :check-module-repeat="true"
                :uploadText="'只可上传视频且大小不超过500M'"
              />
            </div>
          </div>
        </div>
        <div class="submit">
          <van-button
            class="skuTaskSubmit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
          >
            点我提交!
          </van-button>
          <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
        </div>
      </div>
      <div v-else class="error-text flex-box flex-column flex-center">
        <p class="title">请重新生成链接</p>
        <p class="content">{{errorText}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { verifyQuery, getOssSecret, proxyHost, submitSourceUrl, isTestEnv, reportBuriedPoint } from '@/apis'
import { Toast, Dialog } from 'vant'
import { errorTextMap } from './config'
import { updateUniqueFileList } from "@/tools/file-upload";
import { checkUploadImages } from '../shared'
import imageCollection from "../ImageCollection/Index";
export default {
  components: {
    imageCollection,
  },
  data() {
    return {
      uploadEntries: null,
      isLoading: true,
      accept: 'video/*',
      errorText: '',
      loading: false,
      uploadOptions: null,
    }
  },
  created() {
    this.verifyQuery();
    this.getOssSecret();
  },
  methods: {
    getImgsList() {
      let imgs = []
      this.uploadEntries.forEach((item, index) => {
        imgs = imgs.concat(this.$refs.uploadImg[index].getImagesList())
      })
      return imgs
    },
    async handleSubmit() {
      if (this.loading) return;
      this.loading = true;
      try {
        const imgs = this.getImgsList()
        const submitInfo = await checkUploadImages(imgs)
        if (submitInfo) {
          const validMap = {}
          for (let i in this.uploadEntries) {
            validMap[this.uploadEntries[i].tag] = await this.$refs.uploadImg[i].checkFileValid()
          }
          for (let i in validMap) {
            const verifyValid = validMap[i]
            if (!verifyValid.valid) {
              Toast.fail(verifyValid.messageType ? verifyValid.message : `${i}模块${verifyValid.message}，请重新上传后提交`)
              return
            }
          }
          const options = {
            message: '确认提交？',
            confirmButtonText: '确认提交',
            cancelButtonText: '我再想想',
          }
          await Dialog.confirm(options)
          await this.fetchTaskNew(submitInfo, imgs)
        }
      } catch(e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async fetchTaskNew(submitInfo, imgs) {
      const submitTime = Date.now()
      const waitForSaveList = imgs.filter(item => !item.saveStatus)
      const taskJson = {
        jobId: '',
        cityId: '',
        userTaskId: '',
        dataType: '',
        companyId: '',
        data: '',
      }
      const submitRes = waitForSaveList.map(async (item, index) => {
        item.status = 'uploading'
        item.message = '提交中'
        const { deviceList, picDurationList, picFormatList, picHeightList, picSizeList, picWidthList, uploadStartTimeList, uploadEndTimeList, picCreateList } = submitInfo
        return await submitSourceUrl({
          resourceUrl: item.ossUrl,
          taskInfo: JSON.stringify({
            urlList: [item.ossUrl],
            channel: 'LGTJ',
            fileType: item.type === '图片' ? 'img' : 'video',
            extra: [Object.assign(taskJson, {
              device: deviceList[index],
              version: '',
              picSize: picSizeList[index],
              picFormat: picFormatList[index],
              picCreate: picCreateList[index],
              picDuration: picDurationList[index],
              picWidth: picWidthList[index],
              picHeight: picHeightList[index],
              picCommit: uploadStartTimeList[index],
              picCommitFinish: uploadEndTimeList[index],
              picSDK: 0,
              picSDKFinish: 0,
              compressFileUrl: '',
              host: proxyHost,
              deviceEnd: 'H5',
              text: JSON.stringify({
                submitTime,
                externalLink: location.href,
                dataSubmitEntry: item.imageType
              }) + (isTestEnv ? '***' : ''),
              handleDataType: 'B',
              layoutType: 2
            })],
          }),
          dataType: '',
          taskType: '',
        }).then(res => {
          if (res && res.status === '200') {
            item.status = 'done'
            item.file.saveStatusTxt = '已提交'
            item.saveStatus = true
          } else {
            item.status = 'done'
          }
        }).catch(e => {
          item.status = 'done'
          console.log(e)
        })
      })
      await Promise.all(submitRes)
      const submitSuccessList = waitForSaveList.filter(item => item.saveStatus)
      updateUniqueFileList(submitSuccessList)
      const { bdmis, poiId } = this.$route.query
      const pointRes = waitForSaveList.map(async item => {
          const pointValue = JSON.stringify({
              normalFileName: item.ossUrl,
              fileCreatedTime: item.lastModified,
              startUploadTime: item.uploadStartTime,
              endUploadTime: item.uploadEndTime,
              uploadStatus: item.saveStatus ? 1 : 2,
              source: 2,
              path: process.env.CHANNEL || '',
              env: isTestEnv ? 0 : 1,
              dataSubmitEntry: item.imageType,
              linkType: 1,
              bdmis,
              poiId,
          })
          // 直调后端接口，等外包接口交接过来再切回去
          return await reportBuriedPoint(`[${JSON.stringify({
              pointKey: 'buriedWithoutTask',
              pointValue
          })}]`)
      })
      await Promise.all(pointRes)
      const failList = imgs.filter( item => !item.saveStatus)
      let msg = `提交成功 ${submitSuccessList.length} 个图片/视频，继续加油哦！`
      let confirmButtonText = '继续上传'
      let type = 'alert'
      if (failList.length) {
        msg += `还有 ${failList.length} 上传失败，请重试~`
        confirmButtonText = '再次提交'
        type = 'alert'
      }
      const options = {
        message: msg,
        confirmButtonText,
      }
      try {
        await Dialog[type](options)
        if (!failList.length) {
          this.uploadEntries.forEach((item, index) => {
            this.$refs.uploadImg[index].clearImages()
          })
          this.$router.go(0)
        }
      } catch(e) {
        this.uploadEntries.forEach((item, index) => {
          this.$refs.uploadImg[index].clearImages()
        })
        window.close()
        this.isClose = false
      }
    },
    // 获取阿里云签名
    async getOssSecret() {
        try {
            const res = await getOssSecret()
            if (res.status === '200' && res.data) {
                this.uploadOptions = res.data
                this.uploadTips = ''
            } else {
                this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
                Toast.fail({
                    duration: 4000,
                    message: '获取签名失败，请稍后重试',
                })
            }
        } catch (e) {
            this.uploadTips = '上传token获取失败，请刷新重试或使用APP上传'
            Toast.fail({
                duration: 4000,
                message: e || '获取签名失败',
            })
        }
    },
    async verifyQuery() {
      try {
        const res = await verifyQuery({
          externalLink: window.location.href || ''
        })
        if (res.code === 0) {
          if (res.data.checkStatus === 0) {
            this.uploadEntries = res.data.entries;
          } else {
            this.uploadEntries = null
            this.errorText = errorTextMap[res.data.checkStatus] || '当前链接有误'
          }
        } else {
          Toast.fail(res.msg || '链接校验失败，请稍后重试')
        }
      } catch(e) {
        Toast.fail(e.message || '链接校验失败，请稍后重试')
      } finally {
        this.isLoading = false;
      }
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  }
}
</script>
<style lang="less" scoped>
.hawk-eye {
  .loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .error-text {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    font-weight: 800;
    line-height: 1;
    .title {
      font-size: 30px;
    }
    .content {
      font-size: 18px;
    }
  }
  .upload-module {
    margin: 20px;
    .task-upload {
      width: calc(100% - 30px);
      padding: 15px;
      margin-top: 25px;
      font-size: 14px;
      background-color: #e2e2e3;
      border-radius: 20px;
      .task-upload-file {
        .upload-top {
          text-align: center;
          padding-bottom: 15px;
          .title {
            font-size: 20px;
            font-weight: 800;
          }
        }
      }
    }
    .submit {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin-top: 20%;
      .skuTaskSubmit {
        width: 100%;
        color: #fff;
        background-color: #007aff;
        border-radius: 16px;
      }
      .feedback {
        padding-top: 10px;
        font-size: 14px;
        text-align: center;
        .linkText{
          font-weight: bold;
          color: #3e7bf3
        }
      }
    }
  }
}
</style>